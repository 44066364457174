import { MatPaginatorIntlJaModule } from '@admin/shared/ui/mat-paginator-intl/mat-paginator-intl-ja.module';
import {
  withInterceptorsFromDi,
  provideHttpClient,
} from '@angular/common/http';
import { enableProdMode, importProvidersFrom } from '@angular/core';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MatLegacyDialogModule as MatDialogModule } from '@angular/material/legacy-dialog';
import { MatLegacyPaginatorIntl as MatPaginatorIntl } from '@angular/material/legacy-paginator';
import { MatLegacyProgressSpinnerModule as MatProgressSpinnerModule } from '@angular/material/legacy-progress-spinner';
import { MAT_LEGACY_SNACK_BAR_DEFAULT_OPTIONS as MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/legacy-snack-bar';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { provideAnimations } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app/app-routing.module';
import { AppComponent } from './app/app.component';
import { httpInterceptorProviders } from './app/http-interceptors';
import { AppStoreModule } from './app/store/app-store.module';
import { FinishScheduleStore } from './app/v2/general/application/store/finish-schedule.store';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    importProvidersFrom(
      BrowserModule,
      AppRoutingModule,
      AppStoreModule,
      MatDialogModule,
      MatProgressSpinnerModule,
    ),
    httpInterceptorProviders,
    { provide: MAT_DATE_LOCALE, useValue: 'ja-JP' },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        duration: 2500,
        verticalPosition: 'bottom',
        horizontalPosition: 'right',
        panelClass: 'custom-snackbar',
      },
    },
    {
      provide: MatPaginatorIntl,
      useClass: MatPaginatorIntlJaModule,
    },
    {
      provide: FinishScheduleStore,
      useClass: FinishScheduleStore,
    },
    provideAnimations(),
    provideHttpClient(withInterceptorsFromDi()),
  ],
}).catch((err) => console.log(err));

// Google Tag Manager
const script = document.createElement('script');
script.text = `
(function (w, d, s, l, i) {
        w[l] = w[l] || [];
        w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
        var f = d.getElementsByTagName(s)[0],
          j = d.createElement(s),
          dl = l != 'dataLayer' ? '&l=' + l : '';
        j.async = true;
        j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
        f.parentNode.insertBefore(j, f);
      })(window, document, 'script', 'dataLayer', '${environment.gtmId}');`;
document.head.appendChild(script);
// End Google Tag Manager

// Google Tag Manager (noscript)
const noscript = document.createElement('noscript');
const iframe = document.createElement('iframe');
iframe.src = `https://www.googletagmanager.com/ns.html?id=${environment.gtmId}`;
iframe.height = '0';
iframe.width = '0';
iframe.setAttribute('style', 'display:none;visibility:hidden');
noscript.appendChild(iframe);
document.body.insertBefore(noscript, document.body.firstChild);
// End Google Tag Manager (noscript)

// gtag
gtag('js', new Date());
gtag('config', environment.gaTrackingId);
