import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AdminAuthGuard } from './v2/_guard/admin-auth.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./general/general.routes').then((c) => c.routes),
  },
  {
    path: 'pricing',
    loadChildren: () =>
      import('./v2/pricing/pricing-routes').then((m) => m.PricingRoutes),
  },
  {
    path: 'corporate',
    loadChildren: () =>
      import('./v2/corporate/corporate-routes').then((m) => m.CorporateRoutes),
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.routes').then((c) => c.routes),
    canLoad: [AdminAuthGuard],
  },
  {
    path: 'maker-check',
    loadChildren: () =>
      import('app/maker-check/maker-check.module').then(
        (m) => m.MakerCheckModule,
      ),
  },
  {
    path: 'xr',
    loadChildren: () => import('app/xr/xr.module').then((m) => m.XrModule),
  },
  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled',
      enableTracing: false,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
