import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogModule } from '@angular/material/legacy-dialog';
import { ButtonComponent } from '../../ui/button/button/button.component';

@Component({
    selector: 'ls-message-dialog',
    templateUrl: './message-dialog.component.html',
    styleUrls: ['./message-dialog.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    preserveWhitespaces: false,
    standalone: true,
    imports: [MatLegacyDialogModule, ButtonComponent],
})
export class MessageDialogComponent {
  constructor(
    private dialogRef: MatDialogRef<MessageDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) readonly data: { message: string },
  ) {}

  cancel() {
    this.dialogRef.close(false);
  }
}
